import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <div className="flex flex-col md:flex-row">
                <HashLink className="px-4 font-extrabold text-customColorPrim hover:text-customColorSec" smooth to="/">
                    Home
                </HashLink>

                <HashLink className="px-4 font-extrabold text-customColorPrim hover:text-customColorSec" smooth to="/about">
                    About 
                </HashLink>

                <HashLink className="px-4 font-extrabold text-customColorPrim hover:text-customColorSec" smooth to="/services">
                    Services
                </HashLink>

                <HashLink className="px-4 font-extrabold text-customColorPrim hover:text-customColorSec" to="/portfolio">
                    Portfolio
                </HashLink>

                <HashLink className="px-4 font-extrabold text-customColorPrim hover:text-customColorSec" to="/careers">
                    Careers
                </HashLink>

                <HashLink className="px-4 font-extrabold text-customColorPrim hover:text-customColorSec" to="/contact">
                    Contact Us
                </HashLink>
            </div>
        </>
    )
}

export default NavLinks;
