import React from 'react';
import ImageSlider from './ImageSlider';
import businessImg from "../images/business.jpg";
import solutionsImg from "../images/solutions.png";
import innovationImg from "../images/innovation.jpg";
import intImg from "../images/int.jpg";

const Slider = () => {
  const slides = [
    {
      imageUrl: businessImg,
      title: 'Empowering Businesses with Cutting-edge Solutions.',
      description: 'Transforming challenges into opportunities through innovative software solutions.',
    },
    {
      imageUrl: solutionsImg,
      title: 'Powered by Passionate Experts',
      description: 'Our dedicated team of experts is committed to delivering excellence in every line of code',
    },
    {
      imageUrl: innovationImg,
      title: 'Innovation is Our DNA',
      description: 'Driving progress through continuous innovation and creative problem-solving.',
    },
    {
      imageUrl: intImg,
      title: 'Seamless Integration, Maximum Efficiency',
      description: 'Our solutions seamlessly integrate with your existing infrastructure for optimal efficiency.',
    },
  ];

  return (
    <div className="bg-black-500 h-auto sm:h-96 mt-20 flex items-center justify-center">
      <div className="w-full lg:w-screen bg-customColorPrim rounded shadow-md">
        <ImageSlider slides={slides} />
      </div>
    </div>
  );
};

export default Slider;
