import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validation for each field
    if (name === 'firstName' || name === 'lastName' || name === 'message') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() !== '' ? '' : 'This field is required',
      }));
    } else if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailRegex.test(value) ? '' : 'Invalid email address',
      }));
    } else if (name === 'phone') {
      const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: phoneRegex.test(value) ? '' : 'Invalid phone number',
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for validation errors before submitting
    if (Object.values(errors).some((error) => error !== '')) {
      console.log('Form has validation errors');
      return;
    }

    // Form submission logic here
    try {
     
      const response = await fetch('https://103.159.85.249:8448/kspl_website_backend/api/contacts/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        // Reset form data after successful submission if needed
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
        });
        setSuccessMessage('Form submitted successfully!');
        // Clear success message after a few seconds (adjust as needed)
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      } else {
        console.error('Error submitting the form');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <NavBar />
      <div className="container mx-auto mt-24 flex items-center justify-center">
        <div className="bg-gray-100 shadow-md p-8 mb-4 rounded-md w-full max-w-md">
          <h1 className="text-3xl font-semibold text-customColorPrim mb-6">Contact Us</h1>
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <span>{successMessage}</span>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="firstName" className="block font-bold text-gray-600">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
              <div className="text-red-500 text-sm">{errors.firstName}</div>
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="block font-bold text-gray-600">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
              <div className="text-red-500 text-sm">{errors.lastName}</div>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block font-bold text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
              <div className="text-red-500 text-sm">{errors.email}</div>
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block font-bold text-gray-600">
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md w-full"
              />
              <div className="text-red-500 text-sm">{errors.phone}</div>
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block font-bold text-gray-600">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                className="mt-1 p-2 border rounded-md w-full"
                required
              ></textarea>
              <div className="text-red-500 text-sm">{errors.message}</div>
            </div>
            <div className="flex justify-center">
              <button type="submit" className="bg-customColorPrim text-white p-2 rounded-md hover:bg-customColorSec">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
