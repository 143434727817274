import React from "react";
import ProjectCard from "../pages/ProjectCard";
import NavBar from "../components/Navbar/NavBar";


const projects = [
  {
    id: 1,
    title: 'Jewelry App',
    description: 'GemCraft is an exquisite jewelry app designed for both casual users and jewelry enthusiasts. It offers a seamless browsing experience with a vast collection of stunning jewelry pieces. Users can explore, customize, and even virtually try on jewelry items using augmented reality. GemCraft also integrates secure online purchasing for a hassle-free shopping experience.',
    features: [
      'Virtual Try-On',
      'Extensive Jewelry Collection',
      'Secure Online Transactions',
      'Augmented Reality Integration',
    ],
  },
  {
    id: 2,
    title: 'Kids Learning App',
    description: 'EduPlay is an engaging and educational app designed for young minds. It provides a fun and interactive learning environment for children, covering a wide range of subjects. With colorful visuals, interactive quizzes, and educational games, EduPlay makes learning enjoyable for kids while helping them develop essential skills.',
    features: [
      'Interactive Learning Games',
      'Age-Appropriate Content',
      'Progress Tracking',
      'Parental Controls',
    ],
  },
  {
    id: 3,
    title: 'Society App',
    description: 'ConnectHub is a comprehensive society app designed to enhance community living. It serves as a digital hub for residents, offering features such as event management, communication channels, and community forums. ConnectHub fosters a sense of community by facilitating interaction and collaboration among residents.',
    features: [
      'Event Management',
      'Community Forums',
      'Instant Communication',
      'Announcement Board',
    ],
  },
  // Add more projects as needed
];

const Portfolio = () => {
  return (
    <>
      <div className="mt-20">
        <NavBar />
        <section className="px-4 md:px-10 lg:px-20">
          <h2 className="py-5 w-full border-b-4 border-customColorSec text-center text-3xl text-customColorPrim font-bold">
            Products
          </h2>
          <div className="m-5 p-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {projects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))}
            </div>
          </div>
        </section>
      </div>
      
    </>
  );
};

export default Portfolio;
