import React from "react";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import Slider from "../components/Slider";
import NavBar from "../components/Navbar/NavBar";
const Home = () => {
  return (
    <>
      <NavBar></NavBar>

      <Slider></Slider>

      <Services />
      <Portfolio />
      <Clients />
      <Cta />
      <Footer />
    </>
  );
};

export default Home;
