import React from 'react';
import kws from '../images/clients/gks.png';
import geps from '../images/clients/smart.png';
import protergia from '../images/clients/ks.png';

const clients = [
  { id: 1, name: 'Client A', image: kws },
  { id: 2, name: 'Client B', image: geps },
  { id: 3, name: 'Client C', image: protergia },
];

const Clients = () => {
  return (
    <div className="mt-20">
      <div className="container mx-auto px-4 md:px-10 lg:px-20">
        <h2 className="p-5 border-b-4 border-customColorSec text-center text-3xl text-customColorPrim font-bold">
          Our Clients
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 my-10">
          {clients.map((client) => (
            <div key={client.id} className="bg-gray-100 p-4 rounded-lg shadow-md">
              <img
                src={client.image}
                alt={client.name}
                className="w-full object-cover mb-4 rounded-md"
              />
              {/*<h2 className="text-lg font-semibold mb-2">{client.name}</h2>
              <p className="text-gray-600">Some description about the client.</p>*/}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
