import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-t from-customColorPrim via-customColorPrim to-customColorSec text-white py-8">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center lg:justify-between">
        <div className="p-8 rounded-lg mb-4 lg:mb-0 lg:mr-4">
          <h3 className="text-xl font-bold mb-4 text-white hover:text-gray-400">Contact Us</h3>
          <p className="text-white hover:text-gray-400">Email: info@kspl.com</p>
        </div>
        <div className="p-8 rounded-lg mb-4 lg:mb-0 lg:mr-4">
          <h3 className="text-xl font-bold mb-4 text-white hover:text-gray-400">Quick Links</h3>
          <ul>
            <li>
              <HashLink className="px-4 font-normal text-white hover:text-gray-400" smooth to="/about">
                About Us
              </HashLink>
            </li>
            <li>
              <HashLink className="px-4 font-normal text-white hover:text-gray-400" smooth to="/services">
                Services
              </HashLink>
            </li>
            <li>
              <HashLink className="px-4 font-normal text-white hover:text-gray-400" smooth to="/portfolio">
                Portfolio
              </HashLink>
            </li>
            <li>
              <HashLink className="px-4 font-normal text-white hover:text-gray-400" smooth to="/careers">
                Careers
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="p-8 rounded-lg">
          <h3 className="text-xl font-bold mb-4 text-white hover:text-gray-400">Social Media</h3>
          <div className="flex space-x-4">
            <a href="#" className="text-white hover:text-gray-400">
              <FacebookIcon />
            </a>
            <a href="#" className="text-white hover:text-gray-400">
              <TwitterIcon />
            </a>
            <a href="#" className="text-white hover:text-gray-400">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <p>Copyright &copy; {new Date().getFullYear()}{"  "} KeenWall Software Pvt. Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
