import React from "react";
import Card from "../pages/CardS";
import img from "../images/web.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img4 from "../images/consultation.svg";
import img5 from "../images/cyb.svg";
import img6 from "../images/da.svg";
import NavBar from "../components/Navbar/NavBar";
const Service = () => {
  const data = [
    {
      imageUrl: img,
      title: "Web Development",
      description:
        "We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
    },
    {
      imageUrl: img6,
      title: "Data Analytics and Business Intelligence",
      description:
        "Harness the power of data to make informed business decisions. Our data analytics and business intelligence services enable you to extract valuable insights from your data, facilitating strategic decision-making and driving business growth.",
    },
    {
      imageUrl: img3,
      title: "Domain and Hosting Services",
      description:
        "We provide domain registration and web hosting services to individuals and organizations to enable them to gain visibility in the digital space.",
    },
    {
      imageUrl: img4,
      title: "General IT Consultations",
      description:
        "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
    },
    {
      imageUrl: img5,
      title: "Cybersecurity Solutions",
      description:
        "Protect your business from evolving cyber threats with our robust cybersecurity solutions. Our comprehensive approach includes risk assessments, threat detection, and implementation of advanced security measures to safeguard your sensitive data and maintain regulatory compliance.",
    },
    {
      imageUrl: img2,
      title: "Mobile App Development",
      description:
        "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
    },
    // Add more data as needed
  ];

  return (
    <div className="mt-20">
      <NavBar />
      <section className="px-4 md:px-10 lg:px-20">
        <h2 className="p-5 text-center text-3xl text-customColorPrim font-bold">
          Our Services
        </h2>
        <div className="flex justify-center">
          <div className="w-full border-b-4 border-customColorSec">
            <h2 className="my-2 mx-4 lg:mx-12 text-center text-lg lg:text-2xl font-semibold text-customColorPrim">
              We are deeply committed to the growth and success of our clients.
            </h2>
          </div>
        </div>
        <div className="py-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {data.map((item, index) => (
              <Card key={index} {...item} />
            ))}
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default Service;
