// Card.js

import React from 'react';

const CardS = ({ imageUrl, title, description }) => {
  return (
    

<div className="bg-white w-full transition-all ease-in-out duration-400  overflow-hidden text-gray-800 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
<div className="m-2 w-full text-justify text-sm">
    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-72 w-full" src={imageUrl} />
    <h2 className="font-semibold my-4 text-2xl text-center">{title}</h2>
    <p className="text-md px-2  font-medium">{description} </p>
</div>
</div>


  );
};

export default CardS;
