// ProjectCard.jsx

import React from "react";

const ProjectCard = ({ project }) => {
  return (
    <div className="bg-white shadow-md p-6 rounded-md mb-6">
      <h2 className="text-xl font-bold mb-4">{project.title}</h2>
      <p className="text-gray-800 mb-4">{project.description}</p>
      <ul className="list-disc ml-6">
        {project.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

export default ProjectCard;
