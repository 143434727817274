import React from 'react';
import NavBar from "../components/Navbar/NavBar";
import heroImg from "../images/web-dev.svg";
import img from '../images/Web-developer.svg';
import Footer from '../components/Footer';

const cardData = [
  {
    title: 'Our Vision',
    titleDescription: 'At KeenWall Software Private Limited, we envision a world where technology seamlessly integrates with daily life, transforming challenges into opportunities. Our commitment is to be at the forefront of this digital revolution, providing innovative solutions that empower businesses and individuals to thrive in the ever-evolving landscape of technology.',
    subheading: 'Who We Are',
    subheadingDescription: 'KeenWall Software Private Limited was established in 2023 with a mission to revolutionize the way businesses leverage technology. We are a team of dedicated professionals – developers, designers, strategists, and visionaries – united by a passion for innovation and a drive to create solutions that make a real impact.',
    subsubheadings: [
      { subsubheading: 'Our Mission', subsubheadingDescription: 'Our mission is to empower businesses through innovative and tailored technology solutions. We strive to be a catalyst for positive change, helping our clients achieve their goals and stay ahead in a rapidly evolving technological landscape.' },
    ],
    imageUrl: null,
  },
  {
    title: null,
    titleDescription: null,
    subheading: null,
    subheadingDescription: null,
    subsubheadings: [
      { subsubheading: 'Innovation', subsubheadingDescription: 'We embrace innovation as a way of life. Constantly exploring new technologies and methodologies, we strive to bring fresh ideas and inventive solutions to every project.' },
    ],
    imageUrl: heroImg,
  },
  {
    title: 'Our Core Values',
    titleDescription: null,
    subheading: null,
    subheadingDescription: null,
    subsubheadings: [
      { subsubheading: 'Innovation', subsubheadingDescription: 'We embrace innovation as a way of life. Constantly exploring new technologies and methodologies, we strive to bring fresh ideas and inventive solutions to every project.' },
      { subsubheading: 'Collaboration', subsubheadingDescription: 'Collaboration is the heartbeat of KeenWall Software Private Limited. We believe in the power of teamwork and actively seek diverse perspectives to enhance creativity and problem-solving.' },
      { subsubheading: 'Excellence', subsubheadingDescription: 'Excellence is our standard. From the conceptualization phase to the final delivery, we are committed to achieving the highest quality in everything we do.' },
      { subsubheading: 'Client-Centric Approach', subsubheadingDescription: 'Our clients are partners on the journey. We listen attentively, understand their unique needs, and tailor our solutions to exceed expectations, fostering long-term relationships built on trust and satisfaction.' }
    ],
    imageUrl: null,
  },
  {
    title: 'What Sets Us Apart',
    titleDescription: null,
    subheading: null,
    subheadingDescription: null,
    subsubheadings: [
      { subsubheading: 'Expertise', subsubheadingDescription: 'With a team of seasoned experts in software development, data analytics, and IT consulting, we bring a wealth of knowledge and experience to the table. Our diverse skill set allows us to address the unique challenges that businesses face, ensuring a comprehensive and effective approach to solution delivery.' },
      { subsubheading: 'Client-Centric Approach', subsubheadingDescription: 'At KeenWall Software Private Limited, we believe in building lasting partnerships with our clients. We prioritize understanding your business needs and goals, working collaboratively to create solutions that align with your vision. Your success is our success, and we are committed to delivering value at every stage of our engagement.' },
      { subsubheading: 'Innovation Hub', subsubheadingDescription: 'Innovation is at the heart of what we do. Our dedicated research and development team stays abreast of the latest industry trends and emerging technologies. This commitment to innovation enables us to deliver solutions that not only meet current needs but also anticipate future challenges.' },
    ],
    imageUrl: null,
  },
  {
    title: 'Our Commitment to Quality',
    titleDescription: 'Quality is non-negotiable at KeenWall Software Private Limited. We adhere to the highest industry standards in our development and consulting processes, ensuring that every solution we deliver is reliable, secure, and scalable.',
    subheading: 'Join Us on the Journey',
    subheadingDescription: 'As we continue to push the boundaries of whats possible in technology, we invite you to join us on this exciting journey. Whether you are a small startup or a large enterprise, KeenWall Software Private Limited is here to be your partner in innovation, driving your success through technology.For inquiries and collaborations, contact us today.',
    subsubheadings: [],
    imageUrl: img,
  },
  {
    title: 'Our Services',
    titleDescription: null,
    subheading: null,
    subheadingDescription: null,
    subsubheadings: [
      { subsubheading: 'Software Development', subsubheadingDescription: 'We specialize in crafting bespoke software solutions tailored to your business requirements. From web applications to mobile apps, our development team leverages the latest technologies to deliver robust and scalable solutions.' },
      { subsubheading: 'Data Analytics', subsubheadingDescription: 'Unlock the power of your data with our advanced analytics services. We help you derive actionable insights, optimize decision-making processes, and drive business growth through data-driven strategies.' },
      { subsubheading: 'IT Consulting', subsubheadingDescription: 'Our experienced consultants provide strategic guidance to help you navigate the complex world of information technology. From infrastructure optimization to digital transformation, we offer comprehensive consulting services to enhance your IT landscape.' },
    ],
    imageUrl: null,
  },
  
  {
    title: 'Our Commitment to Quality',
    titleDescription: 'Quality is non-negotiable at KeenWall Software Private Limited. We adhere to the highest industry standards in our development and consulting processes, ensuring that every solution we deliver is reliable, secure, and scalable.',
    subheading: null,
    subheadingDescription: null,
    subsubheadings: [],
    imageUrl: null,
  },
  {
    title: null,
    titleDescription: null,
    subheading: 'Join Us on the Journey',
    subheadingDescription: 'As we continue to push the boundaries of whats possible in technology, we invite you to join us on this exciting journey. Whether you are a small startup or a large enterprise, KeenWall Software Private Limited is here to be your partner in innovation, driving your success through technology.For inquiries and collaborations, contact us today.',
    subsubheadings: [],
    imageUrl: null,
  },
];

const AboutUs = () => {
  return (
    <div className="mt-20">
      <NavBar />
      <div className="container mx-auto m-8 px-12 ">
        <h1 className="text-3xl text-customColorPrim font-bold mb-5">About KeenWall Software Private Limited</h1>
        <h1 className="text-xl text-customColorPrim font-bold mb-5">Welcome to KeenWall Software Private Limited – Your Partner in Technological Innovation!</h1>
      </div>
      <div className="container  px-12 mx-auto my-8 grid grid-rows-1 sm:grid-rows-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8" >
        {cardData.map((card, index) => (
          <div key={index} className="shadow-2xl p-4 rounded-lg">
            {card.imageUrl && (
              <div className="mt-4">
                <img src={card.imageUrl} alt={`${card.title} Image`} className="w-full h-auto rounded-md" />
              </div>
            )}
            {!card.imageUrl && (
              <>
                {card.title && (
                  <>
                    <h2 className="text-xl font-bold mb-2">{card.title}</h2>
                    <p>{card.titleDescription}</p>
                  </>
                )}
                {card.subheading && (
                  <>
                    <h1 className="text-lg font-bold mt-4 mb-2">{card.subheading}</h1>
                    {card.subheadingDescription}
                  </>
                )}
                {card.subsubheadings && card.subsubheadings.length > 0 && (
                  <ul className="list-none pl-0 mt-2">
                    {card.subsubheadings.map((subsubheading, subIndex) => (
                      <li key={subIndex}>
                        {subsubheading.subsubheading && (
                          <h1 className="text-lg font-bold mt-4 mb-1">{subsubheading.subsubheading}</h1>
                        )}
                        {subsubheading.subsubheadingDescription && <p>{subsubheading.subsubheadingDescription}</p>}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
