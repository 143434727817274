import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const ImageSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 3000);

    return () => clearInterval(intervalId);
  }, [slides.length]);

  return (
    <div className="relative w-full h-96 overflow-hidden">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full transition-all duration-500 flex justify-center items-center ${
            index === currentSlide ? "opacity-100 scale-100" : "opacity-0 scale-90"
          }`}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-customColorPrim p-4 flex flex-col justify-center items-center text-center">
              <h1 className="text-2xl md:text-3xl lg:text-4xl text-white font-bold mb-2">{slide.title}</h1>
              <h2 className="text-lg md:text-xl lg:text-2xl text-white">{slide.description}</h2>
            </div>
            <div className="bg-customColorPrim flex items-center justify-center">
              <img src={slide.imageUrl} alt={`Slide ${index + 1}`} className="max-h-full w-auto" />
            </div>
          </div>
        </div>
      ))}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600"
        onClick={prevSlide}
      >
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600"
        onClick={nextSlide}
      >
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default ImageSlider;
