import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

const jobPositions = [
  {
    title: 'Software Engineer - Android App Developer',
    description: 'We are looking for an experienced Android App Developer to join our mobile development team.',
    responsibilities: [
      'Design and build advanced applications for the Android platform.',
      'Collaborate with cross-functional teams to define, design, and ship new features.',
      'Work with outside data sources and APIs.',
      'Unit-test code for robustness, including edge cases, usability, and general reliability.',
      'Continuously discover, evaluate, and implement new technologies to maximize development efficiency.',
    ],
    qualifications: [
      'Bachelor\'s degree in Computer Science or a related field.',
      'Proven software development experience and Android skills development.',
      'Experience with Android SDK, different versions of Android, and how to deal with different screen sizes.',
      'Experience with RESTful APIs and third-party libraries.',
      'Solid understanding of the full mobile development life cycle.',
    ],
    skills: ['Android Development', 'Java', 'Kotlin', 'RESTful APIs'],
  },
  {
    title: 'Software Engineer - Web Developer',
    description: 'Join our web development team and contribute to building cutting-edge web applications.',
    responsibilities: [
      'Develop new user-facing features using React.js and other modern web technologies.',
      'Build reusable components and front-end libraries for future use.',
      'Optimize applications for maximum speed and scalability.',
      'Collaborate with other team members and stakeholders to gather and understand requirements.',
      'Stay up-to-date on emerging technologies and trends in web development.',
    ],
    qualifications: [
      'Bachelor\'s degree in Computer Science or a related field.',
      'Proven work experience as a Front-end Developer.',
      'Strong proficiency in JavaScript, HTML, CSS, and React.js.',
      'Experience with responsive and mobile design.',
      'Understanding of web development best practices.',
    ],
    skills: ['React.js', 'JavaScript', 'HTML', 'CSS', 'Responsive Design'],
  },
  // Add more job positions as needed
];

const Careers = () => {
  return (
    <div className="min-h-screen bg-gray-50 mt-20">
      <NavBar />
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-customColorPrim mb-8">Join Our Team</h1>
          <h2 className="text-xl font-bold text-customColorPrim mb-8">Explore exciting career opportunities with us.</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {jobPositions.map((position, index) => (
              <div key={index} className="bg-white rounded shadow p-6">
                <h2 className="text-lg font-semibold mb-4">{position.title}</h2>
                <p className="text-gray-700 mb-4">{position.description}</p>
                <div>
                  <h3 className="text-md font-semibold mb-2">Responsibilities:</h3>
                  <ul className="list-disc list-inside text-gray-600 mb-4">
                    {position.responsibilities.map((responsibility, index) => (
                      <li key={index}>{responsibility}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-md font-semibold mb-2">Qualifications:</h3>
                  <ul className="list-disc list-inside text-gray-600 mb-4">
                    {position.qualifications.map((qualification, index) => (
                      <li key={index}>{qualification}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-md font-semibold mb-2">Skills:</h3>
                  <ul className="list-disc list-inside text-gray-600 mb-4">
                    {position.skills.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))}
                  </ul>
                </div>
                <button className="bg-customColorPrim text-white py-2 px-4 rounded hover:bg-customColorSec">Mail Resume at hr@kspl.com</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Careers;
