import React from 'react';
import { Link } from 'react-router-dom';

const Cta = () => {
    return ( 
        <div className="w-full flex items-center justify-center bg-gray-800 text-white cta">
            <div className="mx-4 md:mx-8 lg:mx-0 w-full max-w-6xl lg:h-96 text-center lg:text-left py-8 lg:py-16 px-4 md:px-12 lg:px-0 flex flex-col lg:flex-row lg:justify-between items-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4 lg:mb-0">
                        <p className='text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold mb-2 md:mb-4'>Are you ready to scale your business?</p>
                        <p className="text-sm md:text-base lg:text-lg xl:text-xl">Get in touch and let us build something amazing <span className='font-bold'>together!</span></p>
                    </div>
                    
                    <div className="w-full lg:w-72 pt-6 lg:pt-0 lg:mx-12">
                        <Link to="/contact" className="bg-transparent border hover:bg-white text-white hover:text-gray-800 hover:border-transparent justify-center text-center rounded-lg px-6 py-3 flex items-center group">Send a message
                        <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Cta;
